/**
 *
 * @param {HTML Element} dsDiv - Used to check data- attributes for finance integration overrides
 * @returns
 */
export const getDemoFinanceOverride = (dsDiv) => {
    if (!dsDiv) return null;
    let financeOverride = dsDiv.dataset.fodfinanceintegration;
    return financeOverride ?? null;
};

/**
 *
 * @param {HTML Element} dsDiv - Used to check data- attributes for weather effects overrides
 * @returns
 */
export const getDemoWeatherOverride = (dsDiv) => {
    if (!dsDiv) return null;
    let override = dsDiv.dataset.fodweathereffect;
    return override ?? null;
};

export function getConversionCouponOverride() {
    let params = new URLSearchParams(window.location.search);
    let cwid = params.get("cwid");
    return cwid ? cwid : null;
}

/**
 *
 * @param {HTML Element} dsDiv - Drive Service Div to search for the data attributes to override the layout option in the coupons
 * @returns - The override, if detected and null if not
 */
export function getDemoLayoutOverride(dsDiv) {
    if (!dsDiv) return null;
    let override = dsDiv.dataset.fodcouponbuttonlayout;
    return override ?? null;
}

/**
 *
 * @param {HTML Element} dsDiv - Drive Service Div to search for the data attributes to override the number of columns option on the coupon list
 * @returns - The override, if detected and null if not
 */
export function getDemoColumnOverride(dsDiv) {
    if (!dsDiv) return null;
    let override = dsDiv.dataset.fodusethreecolumns;
    return override ?? null;
}

/**
 *
 * @param {HTML Element} dsDiv - Drive Service Div to search for the data attributes to override the social share setting on the coupons
 * @returns - The override, if detected and null if not
 */
export function getDemoSocialShareOverride(dsDiv) {
    if (!dsDiv) return null;
    let override = dsDiv.dataset.fodsocialshare;
    return override ?? null;
}

/**
 *
 * @param {HTML Element} dsDiv - Drive Service Div to search for the data attributes to override the social share setting on the coupons
 * @returns - The override, if detected and null if not
 */
export function getDemoHoveredFirstButton(dsDiv) {
    if (!dsDiv) return null;
    let override = dsDiv.dataset.fodhoverfirstcta;
    return override ?? null;
}
