import createRowElement from "./createRowElement";
import createModalElements from "./createModalElements";
import installFinanceIntegrations from "../store/installFinanceIntegrationDivs";
import installTableRowElements from "../store/installTableRowElements";
import { getDemoFinanceOverride } from "../store/getDemoOverrides";

const renderDriveServiceRows = (data, drive_service_divs) => {
    const { client } = data;
    let { sunbit_integration, dignifi_integration } = client;
    let installShareModal = false;
    for (let divIndex = 0; divIndex < drive_service_divs.length; divIndex++) {
        const element = drive_service_divs[divIndex];
        let financeIntegrationOverride = getDemoFinanceOverride(element);
        if (financeIntegrationOverride) {
            sunbit_integration = financeIntegrationOverride === "sunbit";
            dignifi_integration = financeIntegrationOverride === "dignifi";
        }
        const driveServiceDiv = data.drive_service_divs.find(
            (obj) => obj.id === parseInt(element.id.replace("ds-div-", ""))
        );

        if (driveServiceDiv) {
            const { div_rows } = driveServiceDiv;
            for (let rowIndex = 0; rowIndex < div_rows.length; rowIndex++) {
                const row = div_rows[rowIndex];
                const { type, table_rows } = row;

                if (type === "table_row") {
                    installTableRowElements(table_rows, driveServiceDiv, row);
                } else {
                    createRowElement(type, row, element);
                    if (type === "coupon_list" && !installShareModal) {
                        const { coupon_data } = row;
                        const { coupons } = coupon_data;
                        installShareModal = coupons.filter(
                            (c) =>
                                c.social_media_share &&
                                c.social_graph_image_url !== null
                        ).length
                            ? true
                            : false;
                    } else if (type === "coupon_detail" && !installShareModal) {
                        const { coupon_widget } = row;
                        installShareModal =
                            coupon_widget.social_media_share &&
                            coupon_widget.social_graph_image_url !== null
                                ? true
                                : false;
                    }
                }
            }
        }
    }
    createModalElements(data, installShareModal);
    installFinanceIntegrations(sunbit_integration, dignifi_integration);
};

export default renderDriveServiceRows;
