const installCMSStyles = (CMSStylesheetLink) => {
    if (CMSStylesheetLink && CMSStylesheetLink !== "") {
        const linkEl = document.createElement("link");
        linkEl.id = "fod-cms-styles";
        linkEl.href = CMSStylesheetLink;
        linkEl.rel = "stylesheet";
        document.head.appendChild(linkEl);
    }
};

const installHostStyles = (stylesText, styleSheetLink) => {
    if (stylesText && stylesText !== "") {
        const styleEl = document.createElement("style");
        styleEl.id = "fod-host-name-style-tag";
        styleEl.innerText = stylesText;
        document.head.appendChild(styleEl);
    }
    if (styleSheetLink && styleSheetLink !== "") {
        const linkEl = document.createElement("link");
        linkEl.id = "fod-host-name-styles";
        linkEl.href = styleSheetLink;
        linkEl.rel = "stylesheet";
        document.head.appendChild(linkEl);
    }
};

const installOemStyles = (stylesText) => {
    if (stylesText && stylesText !== "") {
        const styleEl = document.createElement("style");
        styleEl.id = "fod-oem-styles";
        styleEl.innerText = stylesText;
        document.head.appendChild(styleEl);
    }
};

const installStyles = (data) => {
    let { host_name, client } = data;
    let {
        cms_style_template: cmsStyleTemplate,
        website_provider: websiteProvider,
        css_style_sheet_link: hostStylesLink,
        css_styles: hostTextStyles,
    } = host_name;
    let { default_oem } = client;
    let { css_style_sheet: oemTextStyles } = default_oem || {};

    if (
        (cmsStyleTemplate && cmsStyleTemplate.url) ||
        (websiteProvider && websiteProvider.stylesheet_url)
    )
        installCMSStyles(
            cmsStyleTemplate
                ? cmsStyleTemplate.url
                : websiteProvider.stylesheet_url
        );
    if (hostTextStyles || hostStylesLink)
        installHostStyles(hostTextStyles, hostStylesLink);
    if (oemTextStyles) installOemStyles(oemTextStyles);
};

export default installStyles;
