const installFinanceIntegrations = (sunbit, dignifi) => {
    let wrapperDiv = document.createElement("div");
    let id = null;
    if (sunbit) {
        wrapperDiv.className = "dss-sunbit-modal";
        wrapperDiv.id = "dss-sunbit-modal";
        id = "dss-sunbit-modal";
    } else if (dignifi) {
        wrapperDiv.className = "dss-dignifi-modal";
        wrapperDiv.id = "dss-dignifi-modal";
        id = "dss-dignifi-modal";
    }
    // Check to see if the div for the modal already exists
    let divExists = document.getElementById(id);
    if (!divExists) document.body.prepend(wrapperDiv);
};

export default installFinanceIntegrations;
