const installGoogleTagManager = () => {
    const tagId = "fodGADSSpecialsV5ScriptTag";
    if (!document.getElementById(tagId)) {
        let gaHeader = document.createElement("script");
        let gaBody = document.createElement("noscript");
        let iFrame = document.createElement("iframe");
        let gtmId = "GTM-MT64F4S";
        if (process.env.REACT_APP_ENVIRONMENT === 'staging' || window.location.host == 'us-central1-ds-specials-dev.cloudfunctions.net' || window.location.host == 'fixed-ops-digital.github.io') gtmId = "GTM-PJ7QW34";
        let headerScripts = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;
        iFrame.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
        gaHeader.innerText = headerScripts;
        gaHeader.id = tagId;
        document.head.prepend(gaHeader);
        iFrame.height = "0";
        iFrame.width = "0";
        iFrame.style = "display:none;visibility:hidden";
        gaBody.append(iFrame);
        document.body.prepend(gaBody);
    }
};

export default installGoogleTagManager;
