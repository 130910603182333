const installTableRowElements = (rows, ds_div, row_obj) => {
    const viewWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
    );
    const dsDivEl = document.getElementById("ds-div-" + ds_div.id);
    const parentDiv = document.createElement("div");

    // let width be set intrinsically if margin is set so row doesn't overflow off page.
    const parentDivStyles = `
        display: grid;
        grid-template-columns: ${"1fr ".repeat(rows.length)};
        width: ${row_obj.margin ? "unset" : "100%"};
        align-items: center;
        background: ${row_obj.background_color ? row_obj.background_color : "unset"};
        color: ${row_obj.text_color ? row_obj.text_color : "unset"};
        margin: ${row_obj.margin ? row_obj.margin : "unset"};
        padding: ${row_obj.padding ? row_obj.padding : "unset"};
        text-align: ${row_obj.text_align ? row_obj.text_align : "unset"};
        box-sizing: ${row_obj.padding ? "border-box" : "unset"};
        font-size: ${row_obj.font_size ? row_obj.font_size : "inherit"};
    `;

    parentDiv.className = "ds-specials-table-row";
    parentDiv.id = "ds-div-" + ds_div.id;
    parentDiv.style.cssText = parentDivStyles;
    parentDiv.dataset.numberofrows = rows.length;
    for (const row in rows) {
        const dsRow = document.createElement("div");
        dsRow.style.cssText = `width: 100%;`;
        let className = null,
            id = rows[row].id;
        switch (rows[row].type) {
            case "amenities":
                className = "ds-amenities-section-row";
                break;
            case "coupon_list":
                className = "ds-coupon-widget-list-row";
                break;
            case "github_file_contents":
            case "terms_conditions":
                className = "ds-raw-content-row";
                break;
            case "image_gallery":
                className = "ds-image-gallery-row";
                dsRow.style.cssText = `
                    display: flex;
                    align-items: center;
                `;
                break;
            case "map_section":
                className = "ds-map-section-row";
                break;
            default:
                break;
        }
        if (className && id) {
            dsRow.className = className;
            dsRow.id = id;
            parentDiv.appendChild(dsRow);
        }
    }
    if (!dsDivEl.querySelector("[id='" + parentDiv.id + "']"))
        dsDivEl.appendChild(parentDiv);
};

export default installTableRowElements;
