const createModalElements = (data, installShareModal) => {
    if (installShareModal) {
        const id = "dss-share-modal";
        if (!document.getElementById(id)) {
            const modalDiv = document.createElement("div");
            modalDiv.id = id;
            modalDiv.className = "dss-share-modal";
            document.body.prepend(modalDiv);
        }
    }
};

export default createModalElements;
