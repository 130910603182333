export function checkForHomepageWidget(ds_divs) {
    if (!ds_divs) return false;
    else {
        let flag = false;
        ds_divs.forEach((div) => {
            div.div_rows.forEach((row) => {
                if (row.type === "hybrid_swiper") flag = true;
            });
        });
        let hybrid_swiper_row = document.getElementsByClassName(
            "ds-hybrid-swiper-row"
        );
        if (hybrid_swiper_row && hybrid_swiper_row.length) flag = true;
        return flag;
    }
}
