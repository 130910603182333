const installCSSLink = (link, id) => {
    if (!document.getElementById(id)) {
        let linkTag = document.createElement("link");
        linkTag.id = id;
        linkTag.rel = "stylesheet";
        linkTag.href = link;
        document.head.append(linkTag);
    }
};

export default installCSSLink;
