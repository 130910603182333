import clientAppCloudFunction from "./clientAppCloudFunction";

const installScript = (srcEndpoint, id) => {
    if (!document.getElementById(id)) {
        let scriptTag = document.createElement("script");
        scriptTag.src = clientAppCloudFunction + "/" + srcEndpoint;
        scriptTag.id = id;
        scriptTag.defer = true;
        document.body.append(scriptTag);
    }
};

export default installScript;
