/**
 *
 * @param { array } rows - array of all the rows in a drive service div
 * @param { integer } row_id - the row id requested
 * @returns { object } object of desired row
 */

const getRowData = (rows, row_id) => {
    let subRowIdx = null;
    let parentRowIndex = null;
    let row = rows.find((r, parentIndex) => {
        if (r.type === "table_row") {
            return r.table_rows.find((sr, index) => {
                if (sr.id === row_id) {
                    parentRowIndex = parentIndex;
                    subRowIdx = index;
                    return true;
                }
                return false;
            });
        }
        else return r.id === row_id;
    });

    return subRowIdx !== null ? { ...row.table_rows[subRowIdx], parentRowIndex } : { ...row, parentRowIndex };
};

export default getRowData;
