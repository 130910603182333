/**
 * @property { number } xs - 0 Sizing in pixels for xtra-small devices.
 * @property { number } sm - 576 Sizing in pixels for small devices.
 * @property { number } md - 768 Sizing in pixels for medium devices.
 * @property { number } lg - 992 Sizing in pixels for large devices.
 * @property { number } xl - 1200 Sizing in pixels for xtra-large devices.
 * @property { number } xxl - 1400 Sizing in pixels for xtra-xtra-large devices.
 * @return { object } returns breakpoint object containing breakpoint properties.
 */
export const getBreakpoints = () => {
    return {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
        xxxl: 1600,
    };
};

/**
 * @return { number } returns view width of window/document.
 */
export const getViewWidth = () => {
    return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
    );
};