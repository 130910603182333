import clientAppCloudFunction from "./clientAppCloudFunction";

const installCSSFile = (file, id) => {
    if (!document.getElementById(id)) {
        let linkTag = document.createElement("link");
        linkTag.id = id;
        linkTag.rel = "stylesheet";
        linkTag.href = clientAppCloudFunction + "/" + file;
        document.head.append(linkTag);
    }
};

export default installCSSFile;
