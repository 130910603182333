import { getBreakpoints, getViewWidth } from "./cssBreakpoints";

/**
 * Determines if window isMobile, isTablet, or isDesktop and passes to components who's state mutators have been passed to it
 * using the addStateAccessor method. Object can be accessed at window level via window.window.deviceDetect.
 */
export class DeviceDetect {
    constructor({ isTest = false }) {
        this.viewWidth = null;
        this.isMobile = false;
        this.isTablet = false;
        this.isDesktop = false;

        // state mutator functions of each component using device detect,
        // updates states of components on screen resize.
        this.stateAccessors = {};
        this.currentMaxKeyIndex = 0; // used to generate key names, iterates every time addStateAccessor is called.

        /* debounce props */
        this.timer = null; // reference to timer set during debouncing
        this.delay = 250; // time to wait until event has finished in milliseconds
        this.shouldDebounce = true;
        window.window.deviceDetect = this;
        window.addEventListener("resize", this.shouldDebounce ? this.debounceResize : this.handleResize);
        this.handleResize(); // run on initialization so object properties are set on load.
        this.isTest = isTest; // whether deviceDetect is being run in testing suite
    };

    addStateAccessor = ({ stateAccessor }) => {
        if(!stateAccessor) throw new Error('Invalid State Accessor', stateAccessor);
        const key = this.currentMaxKeyIndex;
        this.stateAccessors[key] = stateAccessor;
        this.currentMaxKeyIndex += 1;
        return key;
    };

    removeStateAccessor = ({ key }) => {
        if(this.isTest) return; // if running in tests ignore this function...
        if(!key && key !== 0) throw new Error('Key Not Provided', key);
        if(!this.stateAccessors[key]) throw new Error('Key Not Found on Device Detect', '\naccessors: ', this.stateAccessors, '\nkey provided:', key);
        delete this.stateAccessors[key];
    };

    getWindowData = () => {
        return {
            viewWidth: this.viewWidth,
            isMobile: this.isMobile,
            isTablet: this.isTablet,
            isDesktop: this.isDesktop,
        };
    };

    // reduces resize event calls by waiting until event has finished firing.
    debounceResize = () => {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        };

        this.timer = setTimeout(this.handleResize, this.delay);
    };

    handleResize = () => {
        const keys = Object.keys(this.stateAccessors);
        const viewWidth = getViewWidth();
        const { xs, sm, md, lg, xl, xxl } = getBreakpoints();

        const screenSize = {
            viewWidth,
            isMobile: viewWidth <= sm,
            isTablet: sm < viewWidth && viewWidth <= md,
            isDesktop: md < viewWidth,
        };

        this.viewWidth = screenSize.viewWidth;
        this.isMobile = screenSize.isMobile;
        this.isTablet = screenSize.isTablet;
        this.isDesktop = screenSize.isDesktop;

        // update state of each component that has passed in its state accessor
        keys.forEach((key) => {
            if(this.stateAccessors[key]) {
                this.stateAccessors[key]((prevState) => { 
                    return { ...screenSize, key };
                });
            };
        });
    };
};