/**
 * Function to call all the entrypoint functions registered on the window to re-render anything that's on the page.
 */
function callEntryPointFunctions() {
    let scriptIdToFunction = [
        {
            id: "ds-specials-v5-content-block-script",
            fn: window.renderContentBlock,
        },
        {
            id: "ds-specials-v5-hybrid-swiper-script",
            fn: window.renderHybridSwipers,
        },
        { id: "ds-specials-sunbit-modal-script", fn: window.renderSunbitModal },
        {
            id: "ds-specials-dignifi-modal-script",
            fn: window.renderDignifiModal,
        },
        { id: "ds-specials-share-modal-script", fn: window.renderShareModal },
        { id: "ds-specials-video-row-script", fn: null },
        {
            id: "ds-specials-v5-coupon-widget-script",
            fn: window.renderCouponWidgets,
        },
        {
            id: "ds-specials-v5-banner-swiper-script",
            fn: window.renderBannerSwipers,
        },
        {
            id: "ds-specials-v5-internal-navbar-script",
            fn: window.renderInternalNavbars,
        },
        {
            id: "ds-specials-v5-image-gallery-script",
            fn: window.renderImageGalleries,
        },
        {
            id: "ds-specials-recall-widget-row-script",
            fn: window.renderRecallWidgets,
        },
        {
            id: "ds-specials-v5-amenities-section-script",
            fn: window.renderAmenities,
        },
        { id: "ds-specials-map-section-script", fn: window.renderMapSections },
        { id: "ds-specials-anchor-row-script", fn: window.renderAnchorRows },
        {
            id: "ds-specials-video-row-script",
            fn: window.window.renderVideoRows,
        },
    ];

    for (let i = 0; i < scriptIdToFunction.length; i++) {
        let obj = scriptIdToFunction[i];
        let id = obj.id;
        let el = document.getElementById(id);
        if (el && obj.fn) obj.fn();
    }
}

export default callEntryPointFunctions;
